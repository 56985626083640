import _objectSpread from "D:/projects/operation-management-background/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.number.constructor.js";
import { mapState } from "vuex";
import UploadImg from "@/components/UploadImgThird.vue";
import { KnBannerApi } from '@/api';
export default {
  name: "dcBannerForm",
  components: {
    UploadImg: UploadImg
  },
  props: {
    editId: [String, Number],
    bannerTitle: String,
    type: [String, Number]
  },
  data: function data() {
    return {
      isEdit: '',
      preVisible: false,
      previewImage: "",
      previewVisible: false,
      fetching: false,
      disabledLoading: false,
      //     language:'0' 英文  1 中文
      item: {
        language: this.type == 2 ? '1' : '0',
        effectstatus: false
      }
    };
  },
  computed: _objectSpread({
    isDisabled: function isDisabled() {
      return this.bannerTitle == '查看Banner';
    },
    imgProportion: function imgProportion() {
      return [288, 288];
    }
  }, mapState('knBanner', ['language'])),
  created: function created() {
    if (this.editId) {
      this.getBannerDetail();
    }
  },
  methods: {
    getBannerDetail: function getBannerDetail() {
      var _this = this;
      KnBannerApi.detail(this.editId).then(function (res) {
        _this.item = res;
        console.log('??/res', res);
        _this.item.language = String(res.language);
      });
    },
    cancle: function cancle() {
      this.$emit('handleAddCancle');
    },
    submit: function submit() {
      var _this2 = this;
      if (this.disabledLoading) return;
      this.$refs.dcBannerForm.validate(function (valid) {
        if (valid) {
          _this2.disabledLoading = true;
          var params = _objectSpread({}, _this2.item);
          var method = 'addAndUpdateKnBanner';
          if (_this2.editId) {
            method = 'addAndUpdateKnBanner';
            params.bannerid = _this2.editId;
          }
          KnBannerApi[method](_objectSpread({}, params)).then(function () {
            _this2.toast("操作成功", "success");
            _this2.$emit('handleAddSubmit');
          }).catch(function () {
            _this2.disabledLoading = false;
          });
        } else {
          return false;
        }
      });
    },
    previewImg: function previewImg(data) {
      this.previewImage = data;
      this.previewVisible = true;
    },
    handleCancel: function handleCancel() {
      this.previewVisible = false;
    },
    revalidateField: function revalidateField(field) {
      var _this3 = this;
      setTimeout(function () {
        _this3.$refs.dcBannerForm.validateField(field);
      }, 100);
    }
  }
};